<template>
  <router-view />
</template>

<script>
export default {
  name: 'App',
}
</script>

<style>
html, body {
  margin: 0;
  padding: 0;
  background-color: #F6676E;
  height: 100%;
}

* {
  box-sizing: border-box;
}

#app {
  height: 100%;
}
</style>
